import { Event } from '@livekatsomo/models';
import { isLiveRightNow, isVoDRightNow } from '@livekatsomo/shared/utils';
import {
  UpdateEventFunction,
  UploadProgressFunction,
} from '@livekatsomo/types';
import { useDateFns, useImageUploader } from '@livekatsomo/web/hooks';
import { AssetImage } from '@livekatsomo/web/ui-components/assets';
import { ImageDropBox } from '@livekatsomo/web/ui-components/image-drop-box';
import {
  ChannelOnlyBadge,
  LiveBadge,
  PrivateBadge,
  PublicBadge,
  VodBadge,
  VodEnabledBadge,
} from '@livekatsomo/web/ui-components/layout';
import { NextLinkComposed } from '@livekatsomo/web/ui-components/link';
import { OverlayProgress } from '@livekatsomo/web/ui-components/overlay-progress';
import { UploadButton } from '@livekatsomo/web/ui-components/upload-button';
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

export interface EventCardProps {
  /**
   * The event to display information about.
   */
  event: Event;
  /**
   * A function to call when the "Edit" button is clicked.
   */
  onEdit?: () => void;
  /**
   * A function to call when a new poster image is uploaded.
   */
  onUploadLogo: (
    /**
     * The file to upload.
     */
    file: File,
    /**
     * A function to call when the upload progress changes.
     */
    onUploadProgressChange?: UploadProgressFunction,
  ) => void;
  /**
   * A function to call when the event is updated.
   */
  onUpdate: UpdateEventFunction;
}

/**
 * The EventCard component displays information about an event and allows for editing and uploading a poster image.
 * @returns  The EventCard component.
 */
export function EventCard({
  event,
  onEdit,
  onUploadLogo,
  onUpdate,
}: EventCardProps) {
  const { t } = useTranslation();
  const { image, uploadProgress, handleUploadFile } =
    useImageUploader(onUploadLogo);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop: handleUploadFile,
      noClick: true,
      accept: {
        'image/*': ['.jpeg', '.png', '.webp', '.svg'],
      },
      multiple: false,
    });

  const { formatDistance } = useDateFns();

  const categories = useMemo(
    () =>
      event.customer?.categories?.filter((category) =>
        event.categories?.includes(category.id),
      ),
    [event.customer?.categories, event.categories],
  );

  return (
    <Card
      {...getRootProps()}
      sx={{ position: 'relative' }}
      aria-labelledby="event-card-event-name"
    >
      {image?.preview || event.poster ? (
        <Box sx={{ position: 'relative' }}>
          <CardMedia
            component={AssetImage}
            style={{
              objectFit: 'cover',
            }}
            // height="140"
            asset={image?.preview || event.poster}
            alt={event.name}
          />
          {uploadProgress &&
          image?.file.name &&
          uploadProgress[image?.file.name] &&
          uploadProgress[image?.file.name].progress < 100 ? (
            <OverlayProgress
              uploadProgress={uploadProgress[image?.file.name].progress}
            />
          ) : null}
        </Box>
      ) : null}
      <CardContent>
        <Typography variant="h5" component="div" id="event-card-event-name">
          {event.name}
          {event.archived ? (
            <Chip
              label={t('Archived')}
              size="small"
              color="error"
              component="span"
              sx={{
                ml: 2,
              }}
            />
          ) : null}
        </Typography>
        <Typography variant="subtitle1" component="div">
          {event.description || ''}
        </Typography>
        <Typography variant="caption" data-chromatic="ignore">
          {isLiveRightNow(event)
            ? t('Started {{timeDistance}}', {
                timeDistance: formatDistance(event.startDate, new Date(), {
                  addSuffix: true,
                }),
              })
            : event.startDate > new Date()
              ? t('Starts {{timeDistance}}', {
                  timeDistance: formatDistance(event.startDate, new Date(), {
                    addSuffix: true,
                  }),
                })
              : t('Ended {{timeDistance}}', {
                  timeDistance: formatDistance(event.endDate, new Date(), {
                    addSuffix: true,
                  }),
                })}
        </Typography>
        <Box>
          {isLiveRightNow(event) ? <LiveBadge /> : null}
          {isVoDRightNow(event) ? <VodBadge /> : null}
          {event.vodEnabled ? <VodEnabledBadge /> : null}
          {event.visibility === 'public' && <PublicBadge />}
          {event.visibility === 'channel-only' && <ChannelOnlyBadge />}
          {event.visibility === 'private' && <PrivateBadge />}
        </Box>
        <FormControlLabel
          name="pinToFeatured"
          label={t('Pin to featured events')}
          checked={event.pinToFeatured || false}
          onChange={(e, checked) => onUpdate({ pinToFeatured: checked })}
          control={<Switch />}
        />
        <FormControlLabel
          name="enableSupport"
          label={t('Enable Tawk.to support')}
          checked={event.enableSupport || false}
          onChange={(e, checked) => onUpdate({ enableSupport: checked })}
          control={<Switch />}
        />
        {event.visibility !== 'private' && categories?.length ? (
          <Box>
            <Typography variant="caption">{t('Channel Categories')}</Typography>
            <Box display={'flex'} gap={1}>
              {categories.map((category) => (
                <Chip
                  color="secondary"
                  key={category.id}
                  label={category.name}
                  size="small"
                />
              ))}
            </Box>
          </Box>
        ) : null}
      </CardContent>
      <CardActions>
        <UploadButton
          getInputProps={getInputProps}
          label={t('Upload poster') || 'upload poster'}
        />
        {event.customer ? (
          <Button
            component={NextLinkComposed}
            to={`/${event.customer?.slug}/${event.slug}`}
          >
            {t('View')}
          </Button>
        ) : null}
        {onEdit ? (
          <Button size="small" onClick={onEdit}>
            {t('Edit')}
          </Button>
        ) : null}
      </CardActions>
      <ImageDropBox isDragActive={isDragActive} isDragReject={isDragReject} />
    </Card>
  );
}

export default EventCard;
