import { CustomerDoc, EventDoc } from '@livekatsomo/models';
import { useListMenu } from '@livekatsomo/web/hooks';
import {
  ListItemMenu,
  ListItemMenuProps,
} from '@livekatsomo/web/ui-components/layout';
import List from '@mui/material/List';
import { EventListItem } from './EventListItem';
import { NextLinkComposed } from '@livekatsomo/web/ui-components/link';
import MenuItem from '@mui/material/MenuItem';
import LinkIcon from '@mui/icons-material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTranslation } from 'next-i18next';
import ListSubheader from '@mui/material/ListSubheader';
import {
  reduceToGroupedEvents,
  sortByStartDateAscending,
  sortByStartDateDescending,
} from '@livekatsomo/shared/utils';

export interface EventListProps
  extends Omit<ListItemMenuProps<EventDoc>, 'selectedItem' | 'onClose'> {
  /**
   * The customer object to display.
   */
  customer?: CustomerDoc;
  /**
   * Events to display.
   */
  events?: EventDoc[] | null;
  /**
   * The path to the parent page.
   */
  parentPath?: string;
}

/**
 * Renders a list of events with optional delete and copy functionality.
 * @returns The rendered list of events.
 */
export function EventList({
  customer,
  events,
  parentPath,
  onDelete,
  onCopy,
}: EventListProps) {
  const { selectedItem, openMenu, closeMenu } = useListMenu<EventDoc>();
  const showMenu = Boolean(onDelete || onCopy);
  const { t } = useTranslation();

  if (!events) {
    return null;
  }

  const groupedEvents = reduceToGroupedEvents(events);

  return (
    <List>
      {groupedEvents.live.length > 0 ? (
        <>
          <ListSubheader>{t('Live')}</ListSubheader>
          {groupedEvents.live.sort(sortByStartDateAscending).map((event) => (
            <EventListItem
              key={event.id}
              href={`${parentPath}/${event.customer?.slug}/${event.slug}`}
              showCustomer={
                event.customer && customer?.id !== event.customer.id
              }
              showMenu={showMenu}
              onMenuClick={openMenu}
              event={event}
            />
          ))}
        </>
      ) : null}
      {groupedEvents.upcoming.length > 0 ? (
        <>
          <ListSubheader>{t('Upcoming')}</ListSubheader>
          {groupedEvents.upcoming
            .sort(sortByStartDateAscending)
            .map((event) => (
              <EventListItem
                key={event.id}
                href={`${parentPath}/${event.customer?.slug}/${event.slug}`}
                showCustomer={
                  event.customer && customer?.id !== event.customer.id
                }
                showMenu={showMenu}
                onMenuClick={openMenu}
                event={event}
              />
            ))}
        </>
      ) : null}
      {groupedEvents.vod.length > 0 ? (
        <>
          <ListSubheader>{t('VoD')}</ListSubheader>
          {groupedEvents.vod.sort(sortByStartDateDescending).map((event) => (
            <EventListItem
              key={event.id}
              href={`${parentPath}/${event.customer?.slug}/${event.slug}`}
              showCustomer={
                event.customer && customer?.id !== event.customer.id
              }
              showMenu={showMenu}
              onMenuClick={openMenu}
              event={event}
            />
          ))}
        </>
      ) : null}
      {groupedEvents.past.length > 0 ? (
        <>
          <ListSubheader>{t('Past')}</ListSubheader>
          {groupedEvents.past.sort(sortByStartDateDescending).map((event) => (
            <EventListItem
              key={event.id}
              href={`${parentPath}/${event.customer?.slug}/${event.slug}`}
              showCustomer={
                event.customer && customer?.id !== event.customer.id
              }
              showMenu={showMenu}
              onMenuClick={openMenu}
              event={event}
            />
          ))}
        </>
      ) : null}
      {groupedEvents.archived.length > 0 ? (
        <>
          <ListSubheader>{t('Archived')}</ListSubheader>
          {groupedEvents.archived
            .sort(sortByStartDateDescending)
            .map((event) => (
              <EventListItem
                key={event.id}
                href={`${parentPath}/${event.customer?.slug}/${event.slug}`}
                showCustomer={
                  event.customer && customer?.id !== event.customer.id
                }
                showMenu={showMenu}
                onMenuClick={openMenu}
                event={event}
              />
            ))}
        </>
      ) : null}
      <ListItemMenu
        selectedItem={selectedItem}
        onClose={closeMenu}
        onDelete={onDelete}
        onCopy={onCopy}
      >
        <MenuItem
          component={NextLinkComposed}
          to={`/${selectedItem?.item.customer?.slug}/${selectedItem?.item.slug}`}
        >
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          {t('View event')}
        </MenuItem>
      </ListItemMenu>
    </List>
  );
}

export default EventList;
