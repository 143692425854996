import { EventDoc } from '@livekatsomo/models';
import { isLiveRightNow } from './isLiveRightNow';

export function reduceToGroupedEvents(events: EventDoc[]) {
  return events?.reduce(
    (acc, event) => {
      if (event.archived) {
        acc.archived.push(event);
      } else if (isLiveRightNow(event)) {
        acc.live.push(event);
      } else if (event.startDate > new Date()) {
        acc.upcoming.push(event);
      } else if (
        event.vodEnabled &&
        event.vodExpiryDate &&
        event.vodExpiryDate > new Date()
      ) {
        acc.vod.push(event);
      } else {
        acc.past.push(event);
      }
      return acc;
    },
    {
      live: [] as EventDoc[],
      upcoming: [] as EventDoc[],
      vod: [] as EventDoc[],
      past: [] as EventDoc[],
      archived: [] as EventDoc[],
    },
  );
}
